import React, { Component } from "react";
import { connect } from "react-redux";
import SelectedCardItemImage from "./SelectedCardItemImage";

class SelectedCardsElementGroupImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCardRows = () => {
    let rows = [];
    this.props.selectedCards.forEach((card) =>
      rows.push(
        <SelectedCardItemImage
          deckEditMode={this.props.deckEditMode}
          key={card.code}
          cardData={card}
          cardNum={card.code}
        />
      )
    );
    if (rows.length > 0) {
      rows.push(<div key="break" className="break"></div>);
    }
    return rows;
  };

  render() {
    let rows = this.getCardRows();

    if (rows.length > 0) {
      return this.getCardRows();
    } else {
      return <div />;
    }
  }
}

export default connect(null, null)(SelectedCardsElementGroupImage);
