import React, { Component } from "react";
import { connect } from "react-redux";
import PickableCardItem from "./PickableCardItem";

class PickableCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {clickable: true};
  }

  allowClicking = () => {
    this.setState({clickable: true})
  }

  disableClicking = () => {
    this.setState({clickable: false})
  }

  buildCardItems = () => {
    let cards = [];
    for (let i = 0; i < 12; i++) {
      if (
        this.state.clickable && this.props.tableData.activePack != null &&
        i < this.props.tableData.activePack.cards.length
      ) {
        cards.push(
          <PickableCardItem
            key={i}
            cardNum={i}
            onCardReleased={this.allowClicking}
            onCardSubmitted={this.disableClicking}
            cardData={this.props.tableData.activePack.cards[i]}
          />
        );
      } else {
        cards.push(
          <PickableCardItem
            key={i}
            cardNum={i}
            onCardReleased={this.allowClicking}
            onCardSubmitted={this.disableClicking}
            cardData={{
              image: process.env.PUBLIC_URL + "/FFCard.jpg",
              _id: "",
            }}
          />
        );
      }
    }

    return cards;
  };

  render() {
    return (
      <div>
        <div className="flex-container">{this.buildCardItems()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

export default connect(mapStateToProps, null)(PickableCardList);
