import { CLEAR_RECAP_VIEW, REQUEST_RECAP_VIEW } from "../actions/actions";

function cmp(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

function sortCards(cards) {
  return cards.sort(function (a, b) {
    return (
      cmp(ELEMENTS.indexOf(a.element), ELEMENTS.indexOf(b.element)) ||
      cmp(a.name, b.name)
    );
  });
}

const ELEMENTS = [
  "Fire",
  "Ice",
  "Wind",
  "Earth",
  "Lightning",
  "Water",
  "Light",
  "Dark",
  "Multi",
];

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case CLEAR_RECAP_VIEW:
      return { loading: true };
    case REQUEST_RECAP_VIEW:
      let new_state = {
        ...action.payload.data,
        ...{ loading: false },
      };
      if (new_state.unusedCards) {
        new_state.unusedCards = sortCards(new_state.unusedCards);
      }
      return new_state;
    default:
      return state;
  }
}
