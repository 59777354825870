import {
  ADD_CARD_TO_DECK,
  REMOVE_CARD_FROM_DECK,
  ADD_ALL_TO_DECK,
  INITIAL_DECK_LOAD, REMOVE_ALL_FROM_DECK
} from "../actions/actions";

function cmp(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

function sortCards(cards) {
  return cards.sort(function (a, b) {
    return (
      cmp(TYPES.indexOf(a.type), TYPES.indexOf(b.type)) ||
      cmp(ELEMENTS.indexOf(a.element), ELEMENTS.indexOf(b.element)) ||
      cmp(a.name, b.name)
    );
  });
}

const TYPES = ["Forward", "Summon", "Monster", "Backup"];

const ELEMENTS = [
  "Fire",
  "Ice",
  "Wind",
  "Earth",
  "Lightning",
  "Water",
  "Light",
  "Dark",
    "Multi"
];

export default function (state = [], action) {
  switch (action.type) {
    case ADD_CARD_TO_DECK:
      if (action.payload) {
        let existing_card = state.find(
          (card) => card._id === action.payload._id
        );

        if (!existing_card) {
          return sortCards([...state, action.payload]);
        }
        return state;
      }
      break;
    case REMOVE_CARD_FROM_DECK:
      return state.filter(function (value, index, arr) {
        return value._id !== action.payload;
      });
    case ADD_ALL_TO_DECK:
      return sortCards([...action.payload])
    case REMOVE_ALL_FROM_DECK:
      return [];
    case INITIAL_DECK_LOAD:
      //console.log('initial data: ', [...action.payload.data.deck])
      return sortCards([...action.payload.data.deck])
    default:
      return state;
  }
}
