import React, { Component } from "react";
import { connect } from "react-redux";

class ErrorAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.errorData) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.props.errorData}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  errorData: state.errorData,
});

export default connect(mapStateToProps, null)(ErrorAlert);
