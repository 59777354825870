import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import queryString from 'query-string';

class PlayerJoin extends Component {
  constructor(props) {
    super(props);
    this.attemptJoinTable = this.attemptJoinTable.bind(this);
    this.state = {
      usernameInput: "",
      tableCodeInput: "",
      playerToken: localStorage.getItem('playerToken') || ''
    };
  }

  componentDidMount() {
    this.props.clearError();
    let params = queryString.parse(this.props.location.search)
    if(params && params.tableCode)
    {
      this.setState({tableCodeInput: params.tableCode})
    }
  }

  attemptJoinTable(e) {
    e.preventDefault();
    this.props.joinTable({
      playerInfo: Object.assign({}, this.state),
    }, this.saveTokenToLocalStorage);
    return false;
  }

  saveTokenToLocalStorage = (response) => {
    if(response && response.data && response.data.playerToken) {
      localStorage.setItem('playerToken', response.data.playerToken);
    }
  };

  onInputChange(event) {
    let stateMod = {};
    stateMod[event.target.id] = event.target.value;
    this.setState(stateMod);
  }

  render() {
    if (
      this.props.tableData &&
      this.props.tableData.draftPlayerId &&
      this.props.tableData.draftTableId
    ) {
      return <Redirect to="/table" />;
    }

    return (
      <div className="table-create">
        <form className="table-create-form" onSubmit={this.attemptJoinTable}>
          <div className="form-group">
            <label>Username</label>
            <input
              required
              maxLength={20}
              className="form-control"
              id="usernameInput"
              placeholder="Enter username"
              onChange={this.onInputChange.bind(this)}
            />
          </div>
          <div className="form-group">
            <label>Table Code</label>
            <input
              required
              className="form-control"
              id="tableCodeInput"
              value={this.state.tableCodeInput}
              placeholder="Enter table code"
              onChange={this.onInputChange.bind(this)}
            />
          </div>
          <button type="submit" className="btn btn-primary create-table-button">
            Join Table as Player
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerJoin);
