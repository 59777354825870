import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../actions/actions";

class AllPageNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setTooltip(btn, message) {
    btn.tooltip("hide").attr("data-original-title", message).tooltip("show");
  }

  hideTooltip(btn) {
    setTimeout(function () {
      btn.tooltip("hide");
    }, 1000);
  }

  leaveTable() {
    this.props.leaveTable();
  }

  getLogout = () => {
    if (this.props.tableData._id || this.props.tableData.tableInfo) {
      return (
        <li className="nav-item" key="logout">
          <a
            className="navbar-brand"
            href="/#"
            onClick={() => this.leaveTable()}
          >
            Leave Draft
          </a>
        </li>
      );
    }

    return;
  };

  getSupportLink = () => {
      return (
          <li className="nav-item" key="logout">
              <a
                  className="nav-link"
                  href="/#"
                  onClick={() => window.open(`${window.location.origin.toString()}/support`, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")}
              >
                  Contact
              </a>
          </li>
      );
  }

  getNavbarCollapseContents = () => {
    let navListItems = [];
    if (this.props.tableData._id || this.props.tableData.tableInfo) {
      //render nothing
    } else {
      navListItems.push(
        <li className="nav-item" key="new_table">
          <Link className="nav-link" to="/new_table">
            Create New Draft Table
          </Link>
        </li>
      );
      navListItems.push(
        <li className="nav-item dropdown" key="join_options">
          <a
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            href="/#"
          >
            Join a Draft Table
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/join_table">
              Join as Player
            </Link>
            <Link className="dropdown-item" to="/admin_join">
              Join as Host
            </Link>
          </div>
        </li>
      );
      // navListItems.push(this.getSupportLink());
    }

    return navListItems;
  };

  getNavbarAlwaysVisibleContents = () => {
    let navListItems = [];
    let tableData = null;

    if (this.props.tableData._id) {
      tableData = {
        name: this.props.tableData.draftTableName,
        code: this.props.tableData.draftTableCode,
          format: this.props.tableData.draftTableFormat
      };
    } else if (this.props.tableData.tableInfo) {
      tableData = {
        name: this.props.tableData.tableInfo.tableName,
        code: this.props.tableData.tableInfo.tableCode,
        format: this.props.tableData.tableInfo.draftFormat && this.props.tableData.tableInfo.draftFormat.formatName ? this.props.tableData.tableInfo.draftFormat.formatName : 'Custom Cube'
      };
    }

    if (tableData) {
      navListItems.push(
        <li className="nav-link" key="table_name">
          {tableData.name} (Format: {tableData.format})
        </li>
      );
      navListItems.push(
        <li className="nav-link" key="table_code">
          <span
            onClick={() => {
              navigator.clipboard.writeText(tableData.code);
            }}
          >
            {"Table Code: " + tableData.code}
          </span>
        </li>
      );
    }

    return navListItems;
  };

  render() {
    return (
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark justify-content-between">
        <a className="navbar-brand" href="/#">
          FFTCG Cube Sim
        </a>
        <ul className="navbar-nav">{this.getNavbarAlwaysVisibleContents()}</ul>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {this.getNavbarCollapseContents()}
          </ul>
          <ul className="navbar-nav ml-auto">{this.getLogout()}</ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AllPageNavbar);
