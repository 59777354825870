import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/actions";
import { bindActionCreators } from "redux";

class SupportForm extends Component {
    constructor(props) {
        super(props);
        this.attemptSendEmail = this.attemptSendEmail.bind(this);
        this.state = {
            contactName: "",
            contactMethod: "",
            messageDetails: "",
            submitSuccess: false
        };
    }

    attemptSendEmail(e) {
        e.preventDefault();
        this.props.sendSupportEmail({contactName: this.state.contactName, contactMethod: this.state.contactMethod , messageDetails: this.state.messageDetails  });
        this.setState({submitSuccess: true} )
        return false;
    }

    onInputChange(event) {
        let stateMod = {};
        stateMod[event.target.id] = event.target.value;
        this.setState(stateMod);
    }

    render() {
        if(!this.state.submitSuccess) {
            return (
                <div className="table-create">
                    <form className="support-create-form" onSubmit={this.attemptSendEmail}>
                        <h4>FFTCG Cube Draft Support</h4>
                        <br/>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                required
                                className="form-control"
                                id="contactName"
                                placeholder="Your Name"
                                onChange={this.onInputChange.bind(this)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Your Contact Info (optional)</label>
                            <input
                                className="form-control"
                                id="contactMethod"
                                placeholder="Email, Discord ID, Twitter, Facebook etc."
                                onChange={this.onInputChange.bind(this)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea
                                required
                                className="form-control"
                                style={{minHeight: 100}}
                                id="messageDetails"
                                placeholder="Ask Questions, Report Bugs, Provide Feedback, Feature Requests"
                                onChange={this.onInputChange.bind(this)}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary create-table-button">
                            Submit
                        </button>
                    </form>
                </div>
            );
        }
        else {
            return (
                <div className="support-create-form">
                    <h3>Thanks for the Feedback!</h3>
                    <h5>If necessary we'll be in touch.</h5>
                    <button
                        className="btn btn-primary create-table-button"
                        type="button"
                        onClick={() => window.close()}
                    >
                        Close
                    </button>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportForm);
