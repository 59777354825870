import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import AllPageNavbar from "./components/AllPageNavbar";
import ParticipantViewTable from "./components/participant_components/ParticipantViewTable";
import CreateTable from "./components/table_login/CreateTable";
import PlayerJoin from "./components/table_login/PlayerJoin";
import AdminJoin from "./components/table_login/AdminJoin";
import AdminView from "./components/admin_view/AdminView";
import DeckEditor from "./components/participant_components/DeckEditor";
import RecapView from "./components/participant_components/RecapView";
import ErrorAlert from "./components/ErrorAlert";
import SupportForm from "./components/SupportForm"
import Splash from "./components/table_login/Splash";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import FormatView from "./components/participant_components/FormatView";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/format_card_list/:roomCode" />
        <Route path="/recap/:roomCode" />
        <Route path="/support" />
        <Route path="*" component={AllPageNavbar} />
      </Switch>
      <ErrorAlert />
      <Switch>
        <Route path="/recap/:roomCode" component={RecapView} />
        <Route path="/format_card_list/:roomCode" component={FormatView} />
        <Route path="/support" >
          <SupportForm/>
        </Route>
        <Route path="/table_admin">
          <AdminView />
        </Route>
        <Route path="/new_table">
          <CreateTable />
        </Route>
        <Route path="/join_table" component={PlayerJoin}/>
        <Route path="/admin_join">
          <AdminJoin />
        </Route>
        <Route path="/table">
          <ParticipantViewTable />
        </Route>
        <Route path="/deck">
          <DeckEditor />
        </Route>
        <Route path="/" component={Splash}/>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

export default connect(mapStateToProps, null)(App);
