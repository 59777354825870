import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import RecapPlayer from "./RecapPlayer";
import OrphanedCards from "./OrphanedCards";

class RecapView extends Component {
  componentDidMount() {
    this.props.clearError();
    this.refreshRecapData();
  }

  refreshRecapData() {
    const { roomCode } = this.props.match.params;
    if (roomCode) {
      this.props.getRecapData(roomCode);
    }
  }

  getPlayerViews() {
    let playerViews = [];

    for (let i = 0; i < this.props.recapData.players.length; i++) {
      let player = this.props.recapData.players[i];
      playerViews.push(
        <div key={i} className="center-text">
          <RecapPlayer playerData={player} />
        </div>
      );
    }

    return playerViews;
  }

  render() {
    if (!this.props.recapData.loading) {
      if (this.props.recapData.recapPublic) {
        return (
          <div>
            <br />
            <h1 className="center-text">FFTCG Cube Draft Recap</h1>
            <br />
            <h2 className="center-text">{this.props.recapData.tableName}</h2>
            <br />
            <OrphanedCards unusedCards={this.props.recapData.unusedCards} />
            {this.getPlayerViews()}
          </div>
        );
      } else {
        return <div className="non-public-text">Recap Not Made Public</div>;
      }
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state) => ({
  recapData: state.recapData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecapView);
