import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

class CreateTable extends Component {
  constructor(props) {
    super(props);
    this.attemptCreateTable = this.attemptCreateTable.bind(this);
    this.state = {
      draftName: "",
      draftFormat: ""
    };
  }

  componentDidMount() {
    this.props.clearError();
    this.props.getFormatOptions();
  }

  attemptCreateTable(e) {
    e.preventDefault();
    let params = Object.assign({}, this.state);

    if(params.draftFormat !== "")
    {
      this.props.createTable({
        tableInfo: params,
      });
    }
    else if (this.props.formatsData.length > 0 && this.props.formatsData[0]._id)
    {
      params.draftFormat = this.props.formatsData[0]._id;

      this.props.createTable({
        tableInfo: params,
      });
    }

    return false;
  }

  onInputChange(event) {
    let stateMod = {};
    stateMod[event.target.id] = event.target.value;
    if(event.target.id === 'draftFormat')
    {
      stateMod['customCube'] = ''
    }
    this.setState(stateMod);
  }

  getFormatOptions = () => {
    return this.props.formatsData.map((format, index) => {
      return <option key={index} value={format._id}>{format.formatName}</option>
    })
  };

  render() {
    if (
      this.props.tableData &&
      this.props.tableData.draftTableCode &&
      this.props.tableData.draftTablePasscode
    ) {
      return <Redirect to="/table_admin" />;
    }

    return (
      <div className="table-create">
        <form className="table-create-form" onSubmit={this.attemptCreateTable}>
          <div className="form-group">
            <label>Draft Name</label>
            <input
              required
              className="form-control"
              id="draftName"
              placeholder="Enter draft name"
              onChange={this.onInputChange.bind(this)}
            />
          </div>
          <div className="form-group">
            <label>Format</label>
            <select className="form-control" id="draftFormat" onChange={this.onInputChange.bind(this)} required>
              {this.getFormatOptions()}
            </select>
          </div>
          {this.state.draftFormat === 'CUSTOM' && <div className="form-group">
            <label>Custom Cube (one card code per line)</label>
            <textarea className="form-control" id="customCube" rows="4" cols="50" onChange={this.onInputChange.bind(this)}/>
          </div>}
          <br/>
          <button type="submit" className="btn btn-primary create-table-button">
            Create Table
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
  formatsData: state.formatsData
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTable);
