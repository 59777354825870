import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";

class SelectedCardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  moveCard = (mode, cardData) => {
    if (mode === "Add") {
      this.props.addCardToDeck(cardData);
    } else if (mode === "Remove") {
      this.props.removeCardFromDeck(cardData._id);
    }
  };

  hideCardFromView = (cardId) => {
    this.props.hideCard(this.props.tableData.draftTableId, cardId);
  };

  showCard = (cardId) => {
    this.props.showCard(this.props.tableData.draftTableId, cardId);
  };

  getEyeCon = () => {
    if (
      this.props.deckEditMode !== "Add" &&
      this.props.deckEditMode !== "Remove" &&
      this.props.deckEditMode !== "View"
    ) {
      if (this.props.hiddenCards.includes(this.props.cardData._id)) {
        return (
          <svg
            className="bi bi-eye-fill alignright"
            width="35px"
            height="21px"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => this.showCard(this.props.cardData._id)}
          >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            <path
              fillRule="evenodd"
              d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
            />
          </svg>
        );
      } else {
        return (
          <svg
            className="bi bi-eye-slash-fill alignright"
            width="35px"
            height="21px"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => this.hideCardFromView(this.props.cardData._id)}
          >
            <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z" />
            <path
              fillRule="evenodd"
              d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
            />
          </svg>
        );
      }
    }
    return null;
  };

  render() {
    let hidden =
      this.props.deckEditMode !== "Add" &&
      this.props.deckEditMode !== "Remove" &&
      this.props.deckEditMode !== "View" &&
      this.props.hiddenCards.includes(this.props.cardData._id);
    let hidden_suffix = hidden ? " hidden-card" : "";
    let dual_class = this.props.cardData.elements.length > 1 ? `multi-${this.props.cardData.elements[1].toLowerCase()}` : ''
    let class_name = `row card-list-item card-color-${this.props.cardData.elements[0].toLowerCase()}${hidden_suffix} ${dual_class}`;
    return (
        <div>
          <div
            className={class_name}
            data-tip
            data-for={"selected-card-" + this.props.cardNum.toString()}
            onClick={() =>
              this.moveCard(this.props.deckEditMode, this.props.cardData)
            }
          >
            <div className="col-1 card-list-item-bold card-list-item-cost">
              {this.props.cardData.cost}
            </div>
            <div className="col card-list-item-bold center-left">
              <span>{this.props.cardData.name} </span>
              <span className="card-code">({this.props.cardData.code})</span>
            </div>
            {this.getEyeCon()}
          </div>
          <ReactTooltip
              className="hover-card-image"
              id={"selected-card-" + this.props.cardNum.toString()}
              type="dark"
              place="left"
              effect="solid"
          >
            <img
                alt=""
                className="card-art-large"
                src={this.props.cardData.image}
            />
          </ReactTooltip>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
  hiddenCards: state.hiddenCards,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCardItem);
