import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import SelectedCardsTypeList from "./SelectedCardsTypeList";
import SelectedCardsTypeListImage from "./SelectedCardsTypeListImage";
import Deck from "./Deck";
import { Redirect } from "react-router-dom";

class DeckEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { pickedCardTab: "All", viewType: "Text" };
  }

  componentDidMount() {
    this.props.clearError();
    if (this.props.tableData && this.props.tableData.draftTableId) {
      this.refreshTableData();
      this.props.getInitialDeckData( Object.assign({}, { playerId: this.props.tableData.draftPlayerId }))
    }
  }

  refreshTableData() {
    this.props.getPlayerTableData(
      Object.assign({}, { playerId: this.props.tableData.draftPlayerId })
    );
  }

  changeTypeTab(type) {
    this.setState({ pickedCardTab: type });
  }

  toggleViewType() {
    if (this.state.viewType === "Text") {
      this.setState({ viewType: "Image" });
    } else {
      this.setState({ viewType: "Text" });
    }
  }

  addAll = () => {
    this.props.addAllToDeck(this.props.tableData.draftedCards);
  };

  getCardsOfTypeDisplay = (type) => {
    let cards = [];
    if (this.props.tableData && this.props.tableData.draftedCards) {
      cards = this.getFilteredDraftedCards().filter((card) => {
        return type === "All" || card.type === type;
      });
    }

    return cards.length > 0 ? ` (${cards.length})` : "";
  };

  getFilteredDraftedCards = () => {
    let cards = [];
    if (this.props.tableData && this.props.tableData.draftedCards) {
      let deckCardIds = this.props.deckData.map((card) => {
        return card._id;
      });

      //console.log('card ids:',  this.props.deckData)

      cards = this.props.tableData.draftedCards.filter((card) => {
        return !deckCardIds.includes(card._id);
      });
    }
    return cards;
  };

  getCardTypeViewComponent = (type) => {
    if (this.state.pickedCardTab === type) {
      if (this.state.viewType === "Text") {
        return (
          <SelectedCardsTypeList
            deckEditMode={"Add"}
            cardType={type === "All" ? "All" : type.slice(0, -1)}
          />
        );
      } else {
        return (
          <SelectedCardsTypeListImage
            deckEditMode={"Add"}
            cardType={type === "All" ? "All" : type.slice(0, -1)}
          />
        );
      }
    } else {
      return null;
    }
  };

  render() {
    //console.log(this.props.tableData)
    if (this.props.tableData.draftPlayerId) {
      if (this.props.tableData.draftStatus === "Finished") {
        return (
          <div className="container-fluid">
            <div className="row ">
              <div className="col">
                <div className="shadow p-3 mb-5 bg-white rounded selected-card-group">
                  <div id="textbox">
                    <p className="alignleft card-list-item-bold">
                      Drafted Cards ({this.getFilteredDraftedCards().length})
                    </p>
                    <div className="alignright">
                      <button
                        type="button"
                        className="btn-sm btn-dark"
                        onClick={() => this.addAll()}
                      >
                        Add All
                      </button>
                      <button
                        type="button"
                        className="btn-sm btn-dark slight-left-padding"
                        onClick={() => this.toggleViewType()}
                      >
                        Switch View Type
                      </button>
                    </div>
                  </div>
                  <ul className="nav nav-tabs picked-cards-tabs">
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "All"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("All")}
                      >
                        All{this.getCardsOfTypeDisplay("All")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Forwards"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Forwards")}
                      >
                        Forwards{this.getCardsOfTypeDisplay("Forward")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Summons"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Summons")}
                      >
                        Summons{this.getCardsOfTypeDisplay("Summon")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Monsters"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Monsters")}
                      >
                        Monsters{this.getCardsOfTypeDisplay("Monster")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Backups"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Backups")}
                      >
                        Backups{this.getCardsOfTypeDisplay("Backup")}
                      </button>
                    </li>
                  </ul>
                  {this.getCardTypeViewComponent("All")}
                  {this.getCardTypeViewComponent("Forwards")}
                  {this.getCardTypeViewComponent("Summons")}
                  {this.getCardTypeViewComponent("Monsters")}
                  {this.getCardTypeViewComponent("Backups")}
                </div>
              </div>
              <div className="col-8">
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <Deck />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <Redirect to="/table" />;
      }
    } else {
      return <Redirect to="/join_table" />;
    }
  }
}
const mapStateToProps = (state) => ({
  tableData: state.tableData,
  deckData: state.deckData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeckEditor);
