import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import ReactTooltip from "react-tooltip";

class RecapView extends Component {
  componentDidMount() {
    this.props.clearError();
    this.refreshCardListData();
  }

  refreshCardListData() {
    const { roomCode } = this.props.match.params;
    if (roomCode) {
      this.props.getCardList(roomCode);
    }
  }

  render() {
    if (!this.props.cardListData.loading) {
      return  <div className="card-flex-container wrap">
        {this.props.cardListData.cardList.map((card, index) =>
            <div data-tip data-for={"selected-card-" + index} key={index}>
              <img
                  alt=""
                  className={`card-art-small`}
                  src={card.image}
              />
              <ReactTooltip
                  className="hover-card-image"
                  id={"selected-card-" + index}
                  type="dark"
                  effect="solid"
                  position="right"
                  overridePosition={({left, top}, event, triggerElement, tooltipElement) => {
                    return {
                      top,
                      left: Math.min(left, window.innerWidth - tooltipElement.offsetWidth),
                    };
                  }}
              >
                <img
                    alt=""
                    className="card-art-large"
                    src={card.image}
                />
              </ReactTooltip>
            </div>
        )}
          </div>
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  cardListData: state.cardListData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecapView);
