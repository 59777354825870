import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";

class AdminPlayerRow extends Component {
  kickPlayer(playerId) {
    let params = {
      event: "Kick",
      playerId,
    };
    this.props.sendEvent(this.props.tableData._id, params);
  }

    clearPlayerToken(playerId) {
        let params = {
            event: "ClearPlayerToken",
            playerId,
        };
        this.props.sendEvent(this.props.tableData._id, params);
    }

  render() {
      let gameState = this.props.tableData.draftState;
    return (
      <tr className="center-text">
        <th scope="row" className="player-table-coulmn">
          {this.props.player.seatNum + 1}
        </th>
        <td className="player-table-coulmn">{this.props.player.userName}</td>
        <td className="player-table-coulmn">
          {this.props.tableData.draftState === "Started"
            ? this.props.player.activePacks.length
            : ""}
        </td>
        <td className="player-table-coulmn">
          {this.props.tableData.draftState === "Started"
            ? this.props.player.sealedPacks.length
            : ""}
        </td>
        <td className="player-table-coulmn">
            {(gameState !== "Started" && gameState !== "Finished") &&
                <button
                    className="btn-sm btn-danger margin-10-lr"
                    type="button"
                    onClick={() => this.kickPlayer(this.props.player._id)}
                    >
                    Kick
                </button>}
            <button
                className="btn-sm btn-primary margin-10-lr margin-5-b"
                style={{opacity: !this.props.player.playerToken ? 0.5 : 1}}
                type="button"
                disabled={!this.props.player.playerToken}
                onClick={() => this.clearPlayerToken(this.props.player._id)}
            >
                Clear Token
            </button>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlayerRow);
