import {
  CREATE_TABLE,
  JOIN_TABLE,
  REQUEST_ADMIN_VIEW,
  SEND_EVENT,
  REQUEST_PLAYER_VIEW,
  TAKE_CARD,
  LEAVE_TABLE, REQUEST_QUICK_TABLE_DATA,
} from "../actions/actions";

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_TABLE:
    case REQUEST_ADMIN_VIEW:
    case SEND_EVENT:
    case REQUEST_PLAYER_VIEW:
    case TAKE_CARD:
    case JOIN_TABLE:
      if (action.payload && action.payload.data) {
        //console.log(action.payload.data)
        return { ...action.payload.data };
      }
      break;
    case REQUEST_QUICK_TABLE_DATA:
      if (action.payload && action.payload.data) {
        //console.log('payload data: ', action.payload.data)
        //console.log('new state: ', Object.assign({}, state, action.payload.data))
        return Object.assign({}, state, action.payload.data);
      }
      break;
    case LEAVE_TABLE:
      return {};
    default:
      return state;
  }
}
