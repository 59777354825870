import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";

class Splash extends Component {
  componentDidMount() {
    this.props.clearError();
    this.props.leaveTable();
  }

  render() {
    return (
        <div className="splash">
            <h1>Welcome to FFTCG Online Cube Draft Simulator</h1>
            <br/>
            <h5>The app is used for running 2-8 person FFTCG All Star Cube draft events virtually.</h5>
            <br/>
            <h4>Instructions:</h4>
            <ol>
                <li>Have the host of the event create the draft using Create New Draft Table above.</li>
                <li>Share the room code for the draft with anyone participating and wait for everyone to join.</li>
                <li>After everyone has joined, the host needs to lock the room, shuffle seats, and start the draft.</li>
                <li>When the draft starts, each player will be presented with their first pack:</li>
                <ul>
                    <li>Click the card you wish to take.</li>
                    <li>Click it again to add the card to your pool.</li>
                    <li>Your pack will automatically be passed to the player to your left or right.</li>
                    <li>Wait for your next pack to appear and select cards until you take the last card from a pack.</li>
                    <li>After all of the packs in a round have been emptied, new packs will be automatically opened and the next round will begin.</li>
                </ul>
                <li>Once each player has selected 60 cards, the draft is complete and the application automatically transitions to a deck builder. Each player will constuct a 40 card deck from their selected cards.</li>
                <li>When you are happy (or not) with your deck, click Submit & Export to OCTGN. You'll download an OCTGN compatible deck file you can use to load your drafted deck into OCTGN.</li>
                <li>Whenever the host wishes, they can post a recap page showing each players drafted cards and decklists.</li>
            </ol>
            <h4>Other useful information:</h4>
            <ul>
                <li>During a draft, you can toggle between text and image versions of the lists of your drafted cards.</li>
                <li>To help focus your draft build, you can ignore specific cards you've drafted by clicking the eye icon next to each card in your text draft list.</li>
            </ul>
            <h4>Support:</h4>
            <ul>
                <li>If you run into any errors during a draft, try refreshing your page and logging in again. As long as you use the same browser, username, and table ID combination you should be allowed back in to the draft.</li>
                <ul>
                    <li>If for some reason you need to change browser instances, the table host should be able to clear your authentication and allow you to login from anywhere with your next login attempt.</li>
                </ul>
                <li>If the draft host needs to refresh, take note of the randomly generated table password. You'll need this to gain access to the host panel again.</li>
            </ul>
            <h4>Contact Us:</h4>
            <ul>
                <li>Feel free to reach out about issues, feedback, or suggestions in the following ways:</li>
                <ul>
                    <li>
                        <a
                            href="/#"
                            onClick={() => window.open(`${window.location.origin.toString()}/support`, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")}
                        >
                            Contact Form
                        </a>
                    </li>
                    <li>
                        <a
                            href="/#"
                            onClick={()=> window.open("https://discord.gg/F4sfvrP", "_blank")}
                        >
                            Join the Support Discord
                        </a>
                    </li>
                </ul>
                <li>Since you are reading this, check out: <a
                    href="/#"
                    onClick={()=> window.open("https://top.gg/bot/570428406957735946", "_blank")}
                >
                    Marcie, an FFTCG Card Query Discord Bot
                </a></li>
            </ul>
            <div>This project is not affiliated, associated, authorized, endorsed by, or in any way officially connected with the Final Fantasy Trading Card Game </div>
            <div>FINAL FANTASY, SQUARE ENIX and the SQUARE ENIX logo are trademarks or registered trademarks of Square Enix Holdings Co., Ltd.</div>
        </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(null, mapDispatchToProps)(Splash);
