import { SHOW_CARD, HIDE_CARD, LOAD_HIDDEN } from "../actions/actions";

export default function (state = [], action) {
  switch (action.type) {
    case SHOW_CARD:
      if (!state.includes(action.payload.cardId)) {
        return state;
      } else {
        let index = state.indexOf(action.payload.cardId);
        let hiddenCards = [...state];
        hiddenCards.splice(index, 1);
        localStorage.setItem(
          "hiddenCards$" + action.payload.tableId,
          JSON.stringify(hiddenCards)
        );
        return hiddenCards;
      }
    case HIDE_CARD:
      if (state.includes(action.payload.cardId)) {
        return state;
      } else {
        let hiddenCards = [...state, action.payload.cardId];
        localStorage.setItem(
          "hiddenCards$" + action.payload.tableId,
          JSON.stringify(hiddenCards)
        );
        return hiddenCards;
      }
    case LOAD_HIDDEN:
      try {
        if (action.payload != null) {
          const serializedState = localStorage.getItem(
            "hiddenCards$" + action.payload
          );
          if (serializedState === null) {
            return state;
          }
          return JSON.parse(serializedState);
        }
      } catch (err) {
        console.log("Local storage error", err);
      }
      return state;
    default:
      return state;
  }
}
