import React, { Component } from "react";
import { connect } from "react-redux";
import SelectedCardsElementGroup from "./SelectedCardsElementGroup";

function cmp(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}
const ALL_TYPE_SORT = {
  Forward: 1,
  Summon: 2,
  Monster: 3,
  Backup: 4,
};

const ELEMENTS = [
  "Fire",
  "Ice",
  "Wind",
  "Earth",
  "Lightning",
  "Water",
  "Light",
  "Dark",
    "Multi",
  "Hidden",
];

class SelectedCardsTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCardsOfElement = (element) => {
    let cards = [];
    if (element !== "hidden") {
      if (this.props.tableData && this.props.tableData.draftedCards) {
        cards = this.props.deckEditMode
          ? this.getFilteredDraftedCards()
          : this.props.tableData.draftedCards;
        cards = cards.filter((card) => {
          return (
            (this.props.deckEditMode ||
              !this.props.hiddenCards.includes(card._id)) &&
            card.element.toLowerCase() === element &&
            (this.props.cardType === "All"
              ? true
              : card.type === this.props.cardType)
          );
        });
      }
    } else {
      if (this.props.tableData && this.props.tableData.draftedCards) {
        cards = this.props.deckEditMode
          ? this.getFilteredDraftedCards()
          : this.props.tableData.draftedCards;
        cards = cards.filter((card) => {
          return (
            !this.props.deckEditMode &&
            this.props.hiddenCards.includes(card._id) &&
            (this.props.cardType === "All"
              ? true
              : card.type === this.props.cardType)
          );
        });
      }
    }

    return cards.sort(function (a, b) {
      return (
        cmp(ELEMENTS.indexOf(a.element), ELEMENTS.indexOf(b.element)) ||
        cmp(ALL_TYPE_SORT[a.type], ALL_TYPE_SORT[b.type]) ||
        cmp(a.name, b.name)
      );
    });
  };

  getFilteredDraftedCards = () => {
    let cards = [];
    if (this.props.tableData && this.props.tableData.draftedCards) {
      let deckCardIds = this.props.deckData.map((card) => {
        return card._id;
      });
      cards = this.props.tableData.draftedCards.filter((card) => {
        return !deckCardIds.includes(card._id);
      });
    }
    return cards;
  };

  getElementGroups = () => {
    let groups = [];
    ELEMENTS.forEach((element) =>
      groups.push(
        <SelectedCardsElementGroup
          deckEditMode={this.props.deckEditMode}
          key={element.toLowerCase()}
          elementType={element.toLowerCase()}
          selectedCards={this.getCardsOfElement(element.toLowerCase())}
        />
      )
    );
    return groups;
  };

  render() {
    return <div className="scroll-list">{this.getElementGroups()}</div>;
  }
}
const mapStateToProps = (state) => ({
  tableData: state.tableData,
  deckData: state.deckData,
  hiddenCards: state.hiddenCards,
});

export default connect(mapStateToProps, null)(SelectedCardsTypeList);
