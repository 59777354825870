import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";

class SelectedCardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {clickable: true};
  }

  allowClicking = () => {
    if(this.props.onCardReleased)
    {
      this.props.onCardReleased();
    }
    this.setState({clickable: true})
  }

  selectCard(cardId) {
    if(this.state.clickable) {
      if (cardId === "" || this.props.selectedCard !== cardId) {
        this.props.setSelectedCard(cardId);
      } else {
        if(this.props.onCardSubmitted)
        {
          this.props.onCardSubmitted();
        }
        this.setState({clickable: false})
        this.props.takeCard({
          playerId: this.props.tableData.draftPlayerId,
          packId: this.props.tableData.activePack._id,
          cardId: cardId,
        }, this.allowClicking, this.allowClicking);
      }
    }
  }

  render() {
    return (
      <div className="pickable-card-item">
        <img
          alt=""
          className={`card-art-small ${
            this.props.selectedCard !== "" &&
            this.props.selectedCard === this.props.cardData._id
              ? "selected-card"
              : ""
          }`}
          data-tip
          data-for={"pickable-card-" + this.props.cardNum.toString()}
          src={this.props.cardData.image}
          onClick={() => this.selectCard(this.props.cardData._id)}
        />
        {this.props.cardData._id !== "" ? (
          <ReactTooltip
            className="hover-card-image"
            id={"pickable-card-" + this.props.cardNum.toString()}
            type="dark"
            place="right"
            effect="solid"
          >
            <img
              alt=""
              className="card-art-large"
              src={this.props.cardData.image}
            />
          </ReactTooltip>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCard: state.selectedCard,
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCardItem);
