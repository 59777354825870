import React, { Component } from "react";
import { connect } from "react-redux";
import TableStatus from "./TableStatus";

class TableData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.tableData.draftStatus !== "Started") {
      return (
        <div/>
      );
    } else {
      return (
        <div>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-12">
                <TableStatus tablePlayers={this.props.tableData.tableInfo.players} userName={this.props.tableData.userName} direction={this.props.tableData.draftDirection}/>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

export default connect(mapStateToProps, null)(TableData);
