import { combineReducers } from "redux";
import tableDataReducer from "./tableDataReducer";
import errorDataReducer from "./errorDataReducer";
import selectedCardReducer from "./selectedCardReducer";
import deckReducer from "./deckReducer";
import hiddenCardsReducer from "./hiddenCardsReducer";
import recapReducer from "./recapReducer";
import formatsReducer from "./formatsReducer";
import cardListReducer from "./cardListReducer";

const rootReducer = combineReducers({
  tableData: tableDataReducer,
  selectedCard: selectedCardReducer,
  errorData: errorDataReducer,
  deckData: deckReducer,
  hiddenCards: hiddenCardsReducer,
  recapData: recapReducer,
  formatsData: formatsReducer,
  cardListData: cardListReducer,
});

export default rootReducer;
