import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";

class SelectedCardItemImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  moveCard = (mode, cardData) => {
    if (mode === "Add") {
      this.props.addCardToDeck(cardData);
    } else if (mode === "Remove") {
      this.props.removeCardFromDeck(cardData._id);
    }
  };

  render() {
    let hidden =
      this.props.deckEditMode !== "Add" &&
      this.props.deckEditMode !== "Remove" &&
      this.props.deckEditMode !== "View" &&
      this.props.hiddenCards.includes(this.props.cardData._id);
    let hidden_suffix = hidden ? "hidden-card" : "";
    return (
      <div data-tip data-for={"selected-card-" + this.props.cardNum.toString()}>
        <img
          alt=""
          className={`card-art-xs ${hidden_suffix}`}
          src={this.props.cardData.image}
          onClick={() =>
            this.moveCard(this.props.deckEditMode, this.props.cardData)
          }
        />
        <ReactTooltip
          className="hover-card-image"
          id={"selected-card-" + this.props.cardNum.toString()}
          type="dark"
          place="left"
          effect="solid"
        >
          <img
            alt=""
            className="card-art-large"
            src={this.props.cardData.image}
          />
        </ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
  hiddenCards: state.hiddenCards,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedCardItemImage);
