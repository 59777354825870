import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

class AdminJoin extends Component {
  constructor(props) {
    super(props);
    this.attemptJoinTable = this.attemptJoinTable.bind(this);
    this.state = {
      tablePasscode: "",
      tableCode: "",
    };
  }

  componentDidMount() {
    this.props.clearError();
  }

  attemptJoinTable(e) {
    e.preventDefault();
    this.props.joinTableAsAdmin(Object.assign({}, this.state));
    return false;
  }

  onInputChange(event) {
    let stateMod = {};
    stateMod[event.target.id] = event.target.value;
    this.setState(stateMod);
  }

  render() {
    if (
      this.props.tableData &&
      this.props.tableData.draftTableCode &&
      this.props.tableData.draftTablePasscode
    ) {
      return <Redirect to="/table_admin" />;
    }

    return (
      <div className="table-create">
        <form className="table-create-form" onSubmit={this.attemptJoinTable}>
          <div className="form-group">
            <label>Table Code</label>
            <input
              required
              className="form-control"
              id="tableCode"
              placeholder="Enter table code"
              onChange={this.onInputChange.bind(this)}
            />
          </div>
          <div className="form-group">
            <label>Table Passcode</label>
            <input
              required
              className="form-control"
              id="tablePasscode"
              placeholder="Enter table passcode"
              onChange={this.onInputChange.bind(this)}
            />
          </div>
          <button type="submit" className="btn btn-primary create-table-button">
            Join Table as Host
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminJoin);
