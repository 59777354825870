import React, { Component } from "react";
import SelectedCardItem from "./SelectedCardItem";

class RecapPlayer extends Component {
  getCardColumnsOfType = (type) => {
    return this.getCardsOfType(type).map((card) => {
      return (
        <SelectedCardItem
          deckEditMode={"View"}
          key={card.code}
          cardData={card}
          cardNum={card.code}
        />
      );
    });
  };

  getCardsOfType = (type) => {
    let cards = [];
    if (this.props.playerData) {
      cards = this.props.playerData.deck.filter((card) => {
        return card.type === type;
      });
    }

    return cards;
  };

  getCardsOfTypeDisplay = (type) => {
    return this.getCardsOfType(type).length;
  };

  getUnusedCardColumns = () => {
    let cardColumns = [];

    const splitToChunks = (array, parts) => {
      let result = [];
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
    };

    splitToChunks(this.props.playerData.unusedCards, 4).forEach(
      (columnOfCards, columnIndex) => {
        let cards = [];

        columnOfCards.forEach((card, cardIndex) => {
          cards.push(
            <SelectedCardItem
              deckEditMode={"View"}
              key={card.code}
              cardData={card}
              cardNum={card.code}
            />
          );
        });

        cardColumns.push(
          <div className="col-3 kill-padding" key={columnIndex}>
            {cards}
          </div>
        );
      }
    );

    return cardColumns;
  };

  render() {
    return (
      <div className="center-text">
        <div className="shadow p-3 mb-5 bg-white rounded inline-deck">
          <h2>{this.props.playerData.userName}</h2>
          <div className="container-fluid overflow-y">
            <div className="row ">
              <div className="col-4 kill-padding">
                <div>
                  <div className="element-separator card-list-item-bold">
                    {`Forwards (${this.getCardsOfTypeDisplay("Forward")})`}
                  </div>
                  <div className="container">
                    {this.getCardColumnsOfType("Forward")}
                  </div>
                </div>
              </div>
              <div className="col-4 kill-padding">
                <div>
                  <div className="element-separator card-list-item-bold">
                    {`Summons (${this.getCardsOfTypeDisplay("Summon")})`}
                  </div>
                  <div className="container">
                    {this.getCardColumnsOfType("Summon")}
                  </div>
                  <div className="element-separator card-list-item-bold">
                    {`Monsters (${this.getCardsOfTypeDisplay("Monster")})`}
                  </div>
                  <div className="container">
                    {this.getCardColumnsOfType("Monster")}
                  </div>
                </div>
              </div>
              <div className="col-4 kill-padding">
                <div>
                  <div className="element-separator card-list-item-bold">
                    {`Backups (${this.getCardsOfTypeDisplay("Backup")})`}
                  </div>
                  <div className="container">
                    {this.getCardColumnsOfType("Backup")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <h3>Other Drafted Cards</h3>
          <div className="container-fluid padding-30">
            <div className="row ">{this.getUnusedCardColumns()}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default RecapPlayer;
