import { ERROR_MESSAGE } from "../actions/actions";

export default function (state = null, action) {
  switch (action.type) {
    case ERROR_MESSAGE:
      if (action.payload) {
        if (action.payload.response) {
          if (
            action.payload.response.data &&
            action.payload.response.data.message
          ) {
            return action.payload.response.data.message;
          }
        }
      }
      return "Unknown Error";
    default:
      return null;
  }
}
