import { REQUEST_FORMAT_OPTIONS } from "../actions/actions";

export default function (state = [], action) {
  switch (action.type) {
    case REQUEST_FORMAT_OPTIONS:
      if (action.payload && action.payload.data) {
        let new_state = action.payload.data.sort((a, b) =>
            a.formatNumber < b.formatNumber ? 1 : -1
        );
        new_state.push({_id: "CUSTOM", formatName:"Custom Format...", formatNumber: 0})
        return [...new_state];
      }
      break;
    default:
      return state;
  }
  return state;
}
