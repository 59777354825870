import React, { Component } from "react";
import { connect } from "react-redux";

class DraftedCardsData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getCardsOfElement = this.getCardsOfElement.bind(this);
    this.getCardsOfCost = this.getCardsOfCost.bind(this);
  }

  directionArrow() {
    if (this.props.tableData.draftDirection === "left") {
      return <span className="arrow"> &#8592; </span>;
    } else if (this.props.tableData.draftDirection === "right") {
      return <span className="arrow"> &#8594; </span>;
    }

    return <span className="arrow"> </span>;
  }

  getCardsOfElement(element, fromArray = null, hiddenOnly = false) {
    let cards = [];
    if (fromArray) {
      cards = fromArray.filter((card) => {
        return (!hiddenOnly || this.props.hiddenCards.includes(card._id)) && card.element.toLowerCase() === element.toLowerCase();
      });
    } else if (this.props.tableData && this.props.tableData.draftedCards) {
      cards = this.props.tableData.draftedCards.filter((card) => {
        return (!hiddenOnly || this.props.hiddenCards.includes(card._id)) && card.element.toLowerCase() === element.toLowerCase();
      });
    }

    return cards;
  }

  getCardsOfCost = (cost, hidden) => {
    let cards = [];
    if (this.props.tableData && this.props.tableData.draftedCards) {
      cards = this.props.tableData.draftedCards.filter((card) => {
        return ((hidden && this.props.hiddenCards.includes(card._id)) || (!hidden && !this.props.hiddenCards.includes(card._id)))  && (cost === 0 || card.cost === cost);
      });
    }

    return cards;
  };

    getHiddenElementCardCounts() {
        const ELEMENTS = [
        "Fire",
        "Ice",
        "Wind",
        "Earth",
        "Lightning",
        "Water",
        "Light",
        "Dark",
        "Multi",
        ];
        let rows = [];
        let cardsOfCost = this.getCardsOfCost(0, true);
        if (cardsOfCost.length > 0) {
        rows.push(
        <tr key={'hidden'}>
          <td className="card-list-item-bold">Hidden:</td>
          <td>&nbsp;</td>
          <td>
            {ELEMENTS.map((element, index) => {
                    let cards = this.getCardsOfElement(element, cardsOfCost)

                    //console.log('mapping element:', element)
                    if(element !== 'Multi') {
                        return cards.length > 0 ? (
                            <span
                                className={`card-list-item-bold text-color-${element.toLowerCase()}`}
                                key={index}
                            >
                        &nbsp;
                                {this.getCardsOfElement(element, cardsOfCost).length}
                                &nbsp;
                     </span>
                        ) : null
                    }
                    else
                    {
                        let multi_spans = [];
                        let card_counts = {};
                        cards.forEach(card => {
                            let key = card.elements.join('_');
                            if (key in card_counts)
                            {
                                card_counts[key] = card_counts[key] + 1;
                            }
                            else {
                                card_counts[key] = 1;
                            }
                        });

                        Object.keys(card_counts).forEach(key => {
                            let key_elements = key.split('_');
                            multi_spans.push(
                                <span
                                    className={`card-list-item-bold text-color-${key_elements[0].toLowerCase()} multi-${key_elements[1].toLowerCase()}`}
                                    key={index}
                                >
                                    &nbsp;
                                                {card_counts[key]}
                                                &nbsp;
                                 </span>
                            )
                        });

                        return multi_spans .length > 0 ? multi_spans : null;
                    }
                }
            )}
          </td>
        </tr>
        );
        }
        return (
        <table style={{opacity:0.5}}>
        <tbody>{rows}</tbody>
        </table>
        );
    }


    getRoundNumber() {
        return 5 - this.props.tableData.sealedPackCount;
    }

    getCardsInNextPack() {
        return (60 - this.props.tableData.draftedCards.length) % 12;
    }

  getChoiceTable() {
    const ELEMENTS = [
      "Fire",
      "Ice",
      "Wind",
      "Earth",
      "Lightning",
      "Water",
      "Light",
      "Dark",
        "Multi",
    ];

    let rows = [];
    for (let i = 1; i <= 10; i++) {
      let cardsOfCost = this.getCardsOfCost(i, false);
      if (cardsOfCost.length > 0) {
        rows.push(
            <tr key={i}>
              <td className="card-list-item-bold">{i} CP:</td>
              <td>&nbsp;</td>
              <td>
                  {ELEMENTS.map((element, index) => {
                          let cards = this.getCardsOfElement(element, cardsOfCost)

                          //console.log('mapping element:', element)
                          if(element !== 'Multi') {
                              return cards.length > 0 ? (
                                  <span
                                      className={`card-list-item-bold text-color-${element.toLowerCase()}`}
                                      key={index}
                                  >
                        &nbsp;
                                      {this.getCardsOfElement(element, cardsOfCost).length}
                                      &nbsp;
                     </span>
                              ) : null
                          }
                          else
                          {
                              let multi_spans = [];
                              let card_counts = {};
                              cards.forEach(card => {
                                  let key = card.elements.join('_');
                                  if (key in card_counts)
                                  {
                                      card_counts[key] = card_counts[key] + 1;
                                  }
                                  else {
                                      card_counts[key] = 1;
                                  }
                              });

                              Object.keys(card_counts).forEach(key => {
                                  let key_elements = key.split('_');
                                  multi_spans.push(
                                      <span
                                          className={`card-list-item-bold text-color-${key_elements[0].toLowerCase()} multi-${key_elements[1].toLowerCase()}`}
                                          key={index}
                                      >
                                    &nbsp;
                                          {card_counts[key]}
                                          &nbsp;
                                 </span>
                                  )
                              });

                              return multi_spans .length > 0 ? multi_spans : null;
                          }
                      }
                  )}
              </td>
            </tr>
        );
      }
    }
    return (
        <table>
          <tbody>{rows}</tbody>
        </table>
    );
  }

  render() {
    if (this.props.tableData.draftStatus !== "Started") {
      return (
          <div className="container-fluid center-text">
            <div className="row ">
              <h4>Welcome {this.props.tableData.userName}</h4>
            </div>
            <div className="row ">
              <h4>Waiting for host to start</h4>
            </div>
          </div>
      );
    } else {
        let pack_active = this.props.tableData.activePackCount > 0;
        let cards_in_next_pack = this.getCardsInNextPack();

        if(cards_in_next_pack === 0)
        {
            if(pack_active)
            {
                cards_in_next_pack = 12;
            }
            else {
                cards_in_next_pack = 0;
            }
        }

        let next_round_message = `Cards left in ${pack_active ? 'current' : 'next'} pack: ${cards_in_next_pack}`;

        if(!pack_active && cards_in_next_pack === 0) {
            next_round_message = "Waiting for other players to finish round";
        }

      return (
          <div className="container-fluid" style={{paddingLeft:20, paddingRight:20}}>
          {this.props.tableData.draftStatus === "Started" &&
            <div className="row ">
              <span className="card-list-item-bold passer-name">
                Round {this.getRoundNumber()} of 5
              </span>
            </div>}
              {this.props.tableData.draftStatus === "Started" &&
              <div className="row ">
                  <span className="">
                    {next_round_message}
                  </span>
              </div>}
              <div className="row ">
              <span className="card-list-item-bold passer-name">
                Choice Breakdown
              </span>
            </div>
            <div className="row ">
                {this.getChoiceTable()}
            </div>
            <div className="row ">
                {this.getHiddenElementCardCounts()}
            </div>
          </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
    tableData: state.tableData,
    hiddenCards: state.hiddenCards,
});

export default connect(mapStateToProps, null)(DraftedCardsData);
