import axios from "axios";
const FileDownload = require("js-file-download");
export const CREATE_TABLE = "create_table";
export const JOIN_TABLE = "join_table";
export const SEND_EVENT = "send_event";
export const ERROR_MESSAGE = "error_msg";
export const REQUEST_ADMIN_VIEW = "get_admin_view";
export const REQUEST_PLAYER_VIEW = "get_player_view";
export const REQUEST_QUICK_TABLE_DATA = "get_quick_table_data";
export const SET_SELECTED_CARD = "set_selected_card";
export const TAKE_CARD = "take_card";
export const LEAVE_TABLE = "leave_table";
export const CLEAR_ERROR = "clear_error";
export const REMOVE_ALL_FROM_DECK = "remove_all_cards";
export const ADD_ALL_TO_DECK = "add_all_cards";
export const ADD_CARD_TO_DECK = "add_card";
export const REMOVE_CARD_FROM_DECK = "remove_card";
export const SHOW_CARD = "show_card";
export const HIDE_CARD = "hide_card";
export const LOAD_HIDDEN = "load_hidden";
export const REQUEST_RECAP_VIEW = "get_recap_view";
export const CLEAR_RECAP_VIEW = "clear_recap_view";
export const INITIAL_DECK_LOAD = "load_initial_deck";
export const REQUEST_FORMAT_OPTIONS = "get_format_options";
export const CLEAR_CARD_LIST_VIEW = "CLEAR_CARD_LIST_VIEW";
export const REQUEST_FORMAT_CARD_LIST_VIEW = "REQUEST_FORMAT_CARD_LIST_VIEW";

export const loadHiddenCards = (tableId) => (dispatch) => {
  dispatch({
    type: LOAD_HIDDEN,
    payload: tableId,
  });
};

export const showCard = (tableId, cardId) => (dispatch) => {
  dispatch({
    type: SHOW_CARD,
    payload: { tableId, cardId },
  });
};

export const hideCard = (tableId, cardId) => (dispatch) => {
  dispatch({
    type: HIDE_CARD,
    payload: { tableId, cardId },
  });
};

export const addAllToDeck = (cardData) => (dispatch) => {
  dispatch({
    type: ADD_ALL_TO_DECK,
    payload: cardData,
  });
};

export const RemoveAllFromDeck = (cardData) => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_FROM_DECK,
    payload: cardData,
  });
};

export const addCardToDeck = (cardData) => (dispatch) => {
  dispatch({
    type: ADD_CARD_TO_DECK,
    payload: cardData,
  });
};

export const removeCardFromDeck = (cardId) => (dispatch) => {
  dispatch({
    type: REMOVE_CARD_FROM_DECK,
    payload: cardId,
  });
};

export const setSelectedCard = (cardId) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CARD,
    payload: cardId,
  });
};

export const clearError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
};

export const performPOSTRequest = (
  dispatch,
  URI,
  values,
  actionType,
  successCallback = null,
  errorCallback = null
) => {
  axios
    .post(URI, values)
    .then(function (response) {
      if(dispatch) {
        dispatch({
          type: actionType,
          payload: response,
        });
      }
      if (successCallback) {
        successCallback(response);
      }
    })
    .catch(function (error) {
      if (errorCallback) {
        errorCallback(error);
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: error,
      });
    });
};

export const performGETRequest = (
  dispatch,
  URI,
  params,
  actionType,
  successCallback = null,
  errorCallback = null
) => {
  axios
    .get(URI, params)
    .then(function (response) {
      dispatch({
        type: actionType,
        payload: response,
      });
      if (successCallback) {
        successCallback(response.data);
      }
    })
    .catch(function (error) {
      if (errorCallback) {
        errorCallback(error);
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: error,
      });
    });
};

export const exportDeck = (values, format) => (dispatch) => {
  axios
    .post(`/api/${format}_format`, values)
    .then(function (response) {
      FileDownload(response.data, format === 'octgn' ? "fftcg_draft_deck_octgn.o8d" : "fftcg_draft_deck_untap.txt");
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_MESSAGE,
        payload: error,
      });
    });
};

export const takeCard = (values, successCallback = null, errorCallback = null) => (dispatch) => {
  performPOSTRequest(dispatch, `/api/take_card`, values, TAKE_CARD, successCallback, errorCallback);
};

export const createTable = (values) => (dispatch) => {
  performPOSTRequest(dispatch, `/api/draft`, values, CREATE_TABLE);
};

export const sendEvent = (tableId, values) => (dispatch) => {
  performPOSTRequest(dispatch, `/api/table/${tableId}`, values, SEND_EVENT);
};

export const joinTable = (values, callback = null) => (dispatch) => {
  performPOSTRequest(dispatch, `/api/player`, values, JOIN_TABLE, callback);
};

export const joinTableAsAdmin = (values) => (dispatch) => {
  let params = {
    params: values,
  };
  performGETRequest(dispatch, `/api/draft`, params, JOIN_TABLE);
};

export const sendSupportEmail = (values) => (dispatch) => {
  performPOSTRequest(dispatch, `/api/support`, values, '');
};

export const getFormatOptions = (values) => (dispatch) => {
  let params = {
    params: values,
  };
  performGETRequest(dispatch, `/api/formats`, params, REQUEST_FORMAT_OPTIONS);
};

export const getAdminTableData = (values) => (dispatch) => {
  let params = {
    params: values,
  };
  performGETRequest(dispatch, `/api/draft`, params, REQUEST_ADMIN_VIEW);
};

export const getRecapData = (roomCode) => (dispatch) => {
  dispatch({
    type: CLEAR_RECAP_VIEW,
  });
  performGETRequest(
    dispatch,
    `/api/draft_recap/${roomCode}`,
    {},
    REQUEST_RECAP_VIEW
  );
};

export const getCardList = (roomCode) => (dispatch) => {
  dispatch({
    type: CLEAR_CARD_LIST_VIEW,
  });
  performGETRequest(
      dispatch,
      `/api/format_card_list/${roomCode}`,
      {},
      REQUEST_FORMAT_CARD_LIST_VIEW
  );
};

export const getPlayerTableData = (values, successCallback = null) => (
  dispatch
) => {
  let params = {
    params: values,
  };
  performGETRequest(
    dispatch,
    `/api/player`,
    params,
    REQUEST_PLAYER_VIEW,
    successCallback
  );
};

export const getInitialDeckData = (values, successCallback = null) => (
    dispatch
) => {
  let params = {
    params: values,
  };
  performGETRequest(
      dispatch,
      `/api/player_deck`,
      params,
      INITIAL_DECK_LOAD,
      successCallback
  );
};

export const getQuickPlayerTableData = (values, successCallback = null) => (
    dispatch
) => {
  let params = {
    params: values,
  };
  performGETRequest(
      dispatch,
      `/api/table_data`,
      params,
      REQUEST_QUICK_TABLE_DATA,
      successCallback
  );
};

export const leaveTable = () => (dispatch) => {
  dispatch({
    type: LEAVE_TABLE,
  });
};
