import React, {Component} from "react";
import Xarrow from "react-xarrows";

const canvasStyle = {
    width: "100%",
    background: "white",
    display: "flex",
    height: 'auto',
    justifyContent: 'center'
};

const boxStyle = {
    border: "1px #999 solid",
    borderRadius: "10px",
    textAlign: "center",
    width: "auto",
    minWidth: "100px",
    height: "60px",
    paddingLeft:"10px",
    paddingRight:"10px",
    marginRight:"50px"
};

const currUserBoxStyle = {
    border: "3px #999 solid",
    borderRadius: "10px",
    textAlign: "center",
    width: "auto",
    minWidth: "100px",
    height: "60px",
    paddingLeft:"10px",
    paddingRight:"10px",
    marginRight:"50px"
};

const PLAYER_BOX_SPACING = 150;

class TableStatus extends Component {
    resize = () => this.forceUpdate()

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    render()
    {
        const test = this.props.tablePlayers.sort((a, b) =>
            a.seatNum > b.seatNum ? 1 : -1
        );
        //console.log(test)
        const top_row_player_count = Math.ceil(test.length / 2);
        const boxes = [];
        const lines = [];

        for (let i = 0; i < test.length; i++) {
            if (i + 1 <= top_row_player_count) {
                boxes.push({id: `seat${i + 1}`, x: (i * PLAYER_BOX_SPACING), y: 0, name: test[i].userName, activePackCount: test[i].activePacks.length})
            } else {
                boxes.push({
                    id: `seat${i + 1}`,
                    x: ((top_row_player_count - 1) * PLAYER_BOX_SPACING) - ((i - top_row_player_count) * PLAYER_BOX_SPACING) - ((this.props.tablePlayers.length % 2) * 150),
                    y: 100, name: test[i].userName, activePackCount: test[i].activePacks.length
                })
            }
        }

        if(this.props.direction === 'right') {
            for (let i = 0; i < test.length; i++) {
                lines.push({
                    start: `seat${i + 1}`,
                    end: i + 1 < test.length ? `seat${i + 2}` : `seat1`,
                    headSize: 4,
                    startAnchor: i + 1 < top_row_player_count ? 'right' : i + 1 === top_row_player_count ? 'bottom' : i + 1 === test.length ? 'top' : 'left',
                    endAnchor: i + 1 < top_row_player_count ? 'left' : i + 1 === top_row_player_count ? 'top' : i + 1 === test.length ? 'bottom' : 'right'
                })
            }
        }
        else {
            for (let i = test.length - 1; i >= 0; i--) {
                    lines.push({
                        start: `seat${i + 1}`,
                        end: i - 1 < 0 ? `seat${test.length}` : `seat${i}`,
                        headSize: 4,
                        startAnchor: i === top_row_player_count ? 'top' : i + 1 > top_row_player_count ? 'right' : i + 1 > 1 ? 'left' : 'bottom',
                        endAnchor: i === top_row_player_count ? 'bottom' : i + 1 > top_row_player_count ? 'left' : i + 1 > 1 ? 'right' : 'top'
                    })
            }
        }

        //console.log(lines)

        //console.log('username:', this.props.userName)

        let half_length = Math.ceil(boxes.length / 2);

        return (
            <React.Fragment>
                <div style={canvasStyle} id="canvas">
                    {boxes.slice(0,half_length).map((box, i) => (
                        <div key={i}>
                            <div
                                id={box.id}
                                style={this.props.userName === box.name ? {...currUserBoxStyle, left: box.x, top: box.y} : {...boxStyle, left: box.x, top: box.y}}
                            >
                                <div><span className={this.props.userName === box.name ? `card-list-item-bold` : ''}>{box.name}</span></div>
                                <div style={{marginLeft:16}}>
                                    {
                                        Array.from({ length: box.activePackCount }, (_, k) => (
                                            <img
                                                alt=""
                                                key={k}
                                                style={{marginLeft:-16, width:25, height:28}}
                                                src={process.env.PUBLIC_URL + "/FFCardRotSmall.png"}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <br/>
                </div>
                <br/>
                <br/>
                <div style={canvasStyle} id="canvas">
                    {boxes.slice(half_length,boxes.length).reverse().map((box, i) => (
                        <div key={i}>
                            <div
                                id={box.id}
                                style={this.props.userName === box.name ? {...currUserBoxStyle, left: box.x, top: box.y} : {...boxStyle, left: box.x, top: box.y}}
                            >
                                <div><span className={this.props.userName === box.name ? `card-list-item-bold` : ''}>{box.name}</span></div>
                                <div style={{marginLeft:16}}>
                                    {
                                        Array.from({ length: box.activePackCount }, (_, k) => (
                                            <img
                                                alt=""
                                                key={k}
                                                style={{marginLeft:-16, width:25, height:28}}
                                                src={process.env.PUBLIC_URL + "/FFCardRotSmall.png"}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {lines.map((line, i) => (
                    <Xarrow key={i} {...line} />
                ))}
            </React.Fragment>
        );
    }
};

export default TableStatus;
