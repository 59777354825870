import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import AdminPlayerRow from "./AdminPlayerRow";
import { connectAsAdmin } from "../../api";
const keys = require("../../config/keys");

class AdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.clearError();
    if (this.props.tableData && this.props.tableData._id) {
      this.props.getAdminTableData(
          Object.assign({}, { tableId: this.props.tableData._id })
      );
      connectAsAdmin(this.props.tableData._id, (message) => {
        if (message != null) {
          if (message.roomId === this.props.tableData._id) {
            this.props.getAdminTableData(
              Object.assign({}, { tableId: this.props.tableData._id })
            );
          }
        }
      });
    }
  }

  triggerEvent(action) {
    let params = {
      event: action,
    };
    this.props.sendEvent(this.props.tableData._id, params);
  }

  createEventButton = (action, text) => {
    if (!action || 0 === action.length) {
      return (
        <button
          className="btn btn-primary margin-10-lr margin-5-b"
          key={action}
          disabled
        >
          {text}
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-primary margin-10-lr margin-5-b"
          key={action}
          onClick={() => this.triggerEvent(action)}
        >
          {text}
        </button>
      );
    }
  };

  getTableControls = () => {
    let buttonsList = [];
    let gameState = this.props.tableData.draftState;
    let recapPublic = this.props.tableData.recapPublic;

    if (gameState === "Open") {
      buttonsList.push(this.createEventButton("Shuffle", "Shuffle Players"));
      buttonsList.push(this.createEventButton("Lock", "Lock Players"));
    }

    if (gameState === "Locked") {
      buttonsList.push(this.createEventButton("Shuffle", "Shuffle Players"));
      buttonsList.push(this.createEventButton("Unlock", "Unlock Players"));
      buttonsList.push(this.createEventButton("Start", "Start Draft"));
    }

    if (gameState === "Started") {
      buttonsList.push(this.createEventButton("", "Draft In Progress"));
    }

    if (gameState === "Started" || gameState === "Finished") {
      if (recapPublic) {
        buttonsList.push(this.createEventButton("HideRecap", "Hide Recap"));
      } else {
        buttonsList.push(
            this.createEventButton("PublishRecap", "Publish Recap")
        );
      }
    }

    return buttonsList;
  };

  getPlayerList = () => {
    let playerRows = [];
    let playersList = this.props.tableData.players;

    if (playersList && playersList.length > 0) {
      playersList = playersList
        .slice(0)
        .sort((a, b) => (a.seatNum > b.seatNum ? 1 : -1));

      for (let i = 0; i < playersList.length; i++) {
        playerRows.push(<AdminPlayerRow player={playersList[i]} key={i} />);
      }
    }

    return playerRows;
  };

  render() {
    if (
      this.props.tableData &&
      this.props.tableData._id &&
      this.props.tableData.draftTablePasscode
    ) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="shadow p-3 mb-5 bg-white rounded force-bottom-20">
                <h3 className="admin-table-box-titles">Table Info</h3>
                <p>
                  <b>Draft Name: </b>
                  {this.props.tableData.draftTableName}
                </p>
                <p>
                <b>Draft Format: </b>
                  {this.props.tableData.draftTableFormat}
                </p>
                <p>
                  <b>Table Code: </b>
                  {this.props.tableData.draftTableCode}
                </p>
                <p>
                  <b>Table Passcode (you will need this to access this page again): </b>
                  {this.props.tableData.draftTablePasscode}
                </p>
                <p>
                  <b>Quick Join URL: </b>
                  <a
                      href={`${window.location.origin}/join_table?tableCode=${this.props.tableData.draftTableCode}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {`${window.location.origin}/join_table?tableCode=${this.props.tableData.draftTableCode}`}
                  </a>
                </p>
                <p>
                  <b>Cube List URL: </b>
                  <a
                      href={`${window.location.origin}/format_card_list/${this.props.tableData.draftTableCode}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {`${window.location.origin}/format_card_list/${this.props.tableData.draftTableCode}`}
                  </a>
                </p>
                {this.props.tableData.recapPublic && (
                  <p>
                    <b>Recap Link: </b>
                    <a
                      href={`${window.location.origin}/recap/${this.props.tableData.draftTableCode}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${window.location.origin}/recap/${this.props.tableData.draftTableCode}`}
                    </a>
                  </p>
                )}
                {this.getTableControls()}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="shadow p-3 mb-5 bg-white rounded">
                <h3 className="admin-table-box-titles">Players</h3>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="player-table-coulmn">
                        Seat
                      </th>
                      <th scope="col" className="player-table-coulmn">
                        Username
                      </th>
                      <th scope="col" className="player-table-coulmn">
                        Open Packs
                      </th>
                      <th scope="col" className="player-table-coulmn">
                        Sealed Packs
                      </th>
                      <th scope="col" className="player-table-coulmn"></th>
                    </tr>
                  </thead>
                  <tbody>{this.getPlayerList()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/new_table" />;
    }
  }
}

const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
