import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import SelectedCardItem from "./SelectedCardItem";
import SelectedCardsElementGroupImage from "./SelectedCardsElementGroupImage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const ALL_TYPE_SORT = {
  Forward: 1,
  Summon: 2,
  Monster: 3,
  Backup: 4,
};
function cmp(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

class DeckEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { viewType: "Text" };
  }

  removeAll = () => {
    confirmAlert({
      title: 'Remove All Cards',
      message: 'Are you sure you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.RemoveAllFromDeck()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  toggleViewType() {
    if (this.state.viewType === "Text") {
      this.setState({ viewType: "Image" });
    } else {
      this.setState({ viewType: "Text" });
    }
  }

  getCardRows = (cards) => {
    let rows = [];
    cards.forEach((card) =>
      rows.push(
        <SelectedCardItem
          deckEditMode={"Remove"}
          key={card.code}
          cardData={card}
          cardNum={card.code}
        />
      )
    );
    return rows;
  };

  getCardsOfTypeDisplay = (type) => {
    let cards = [];
    if (this.props.deckData) {
      cards = this.props.deckData.filter((card) => {
        return card.type === type;
      });
    }

    return cards;
  };

  getCardsOfElement = (element) => {
    let cards = [];

    if (this.props.deckData) {
      cards = this.props.deckData.filter((card) => {
        return card.element.toLowerCase() === element;
      });
    }

    return cards.sort(function (a, b) {
      return (
        cmp(ALL_TYPE_SORT[a.type], ALL_TYPE_SORT[b.type]) || cmp(a.name, b.name)
      );
    });
  };

  exportDecktoOCTGN = () => {
    if (this.props.deckData.length > 0) {
      this.props.exportDeck({
        playerId: this.props.tableData.draftPlayerId,
        cards: this.props.deckData.map((card) => card._id),
      }, 'octgn');
    }
  };

  exportDecktoUntap = () => {
    if (this.props.deckData.length > 0) {
      this.props.exportDeck({
        playerId: this.props.tableData.draftPlayerId,
        cards: this.props.deckData.map((card) => card._id),
      }, 'untap');
    }
  };

  getCardTypeViewComponent = () => {
    let forwards = this.getCardsOfTypeDisplay("Forward");
    let summons = this.getCardsOfTypeDisplay("Summon");
    let backups = this.getCardsOfTypeDisplay("Backup");
    let monsters = this.getCardsOfTypeDisplay("Monster");
    if (this.state.viewType === "Text") {
      return (
        <div className="container-fluid overflow-y">
          <div className="row ">
            <div className="col-4 kill-padding">
              <div>
                <div className="element-separator card-list-item-bold">
                  Forwards{forwards.length > 0 ? ` (${forwards.length})` : ""}
                </div>
                <div className="container">{this.getCardRows(forwards)}</div>
              </div>
            </div>
            <div className="col-4 kill-padding">
              <div>
                <div className="element-separator card-list-item-bold">
                  Summons{summons.length > 0 ? ` (${summons.length})` : ""}
                </div>
                <div className="container">{this.getCardRows(summons)}</div>
                <div className="element-separator card-list-item-bold">
                  Monsters{monsters.length > 0 ? ` (${monsters.length})` : ""}
                </div>
                <div className="container">{this.getCardRows(monsters)}</div>
              </div>
            </div>
            <div className="col-4 kill-padding">
              <div>
                <div className="element-separator card-list-item-bold">
                  Backups{backups.length > 0 ? ` (${backups.length})` : ""}
                </div>
                <div className="container">{this.getCardRows(backups)}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      let rows = [];
      if (forwards.length > 0)
        rows.push(
          <SelectedCardsElementGroupImage
            deckEditMode={"Remove"}
            key={"Forward"}
            selectedCards={forwards}
          />
        );

      if (summons.length > 0)
        rows.push(
          <SelectedCardsElementGroupImage
            deckEditMode={"Remove"}
            key={"Summon"}
            selectedCards={summons}
          />
        );
      if (monsters.length > 0)
        rows.push(
          <SelectedCardsElementGroupImage
            deckEditMode={"Remove"}
            key={"Monster"}
            selectedCards={monsters}
          />
        );
      if (backups.length > 0)
        rows.push(
          <SelectedCardsElementGroupImage
            deckEditMode={"Remove"}
            key={"Backup"}
            selectedCards={backups}
          />
        );

      return (
        <div className="main-card-flex-container">
          <div className="card-flex-container wrap">{rows}</div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <div id="textbox">
          <p className="alignleft card-list-item-bold">
            Deck {this.props.deckData ? ` (${this.props.deckData.length})` : ""}
          </p>
          <button
              type="button"
              className="btn-sm btn-danger slight-left-padding"
              style={{marginLeft:'30px'}}
              onClick={this.removeAll}
          >
            Remove All
          </button>
          <div className="alignright">
            <button
                type="button"
                className="btn-sm btn-dark"
                onClick={() => this.exportDecktoUntap()}
            >
              {"Export to Untap.in"}
            </button>
            <button
              type="button"
              className="btn-sm btn-dark slight-left-padding"
              onClick={() => this.exportDecktoOCTGN()}
            >
              {"Export to OCTGN"}
            </button>
            <button
              type="button"
              className="btn-sm btn-dark slight-left-padding"
              onClick={() => this.toggleViewType()}
            >
              Switch View Type
            </button>
          </div>
        </div>
        <br />
        <hr />
        {this.getCardTypeViewComponent()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  tableData: state.tableData,
  deckData: state.deckData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeckEditor);
