import React, { Component } from "react";
import SelectedCardItem from "./SelectedCardItem";

class OrphanedCards extends Component {
  getUnusedCardColumns = () => {
    let cardColumns = [];

    const splitToChunks = (array, parts) => {
      let result = [];
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
    };

    splitToChunks(this.props.unusedCards, 4).forEach(
      (columnOfCards, columnIndex) => {
        let cards = [];

        columnOfCards.forEach((card, cardIndex) => {
          cards.push(
            <SelectedCardItem
              deckEditMode={"View"}
              key={card.code}
              cardData={card}
              cardNum={card.code}
            />
          );
        });

        cardColumns.push(
          <div className="col-3 kill-padding" key={columnIndex}>
            {cards}
          </div>
        );
      }
    );

    return cardColumns;
  };

  render() {
    return (
      <div className="center-text">
        <div className="shadow p-3 mb-5 bg-white rounded inline-deck">
          <h3>Extra Cards</h3>
          <h6 className="admin-table-box-titles">
            These cards didn't make it into a pack
          </h6>
          <div className="container-fluid padding-30">
            <div className="row ">{this.getUnusedCardColumns()}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default OrphanedCards;
