import { SET_SELECTED_CARD, TAKE_CARD } from "../actions/actions";

export default function (state = "", action) {
  switch (action.type) {
    case SET_SELECTED_CARD:
      if (action.payload) {
        let newState = action.payload;
        return newState;
      }
      break;
    case TAKE_CARD:
      return "";
    default:
      return state;
  }
  return state;
}
