import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/actions";
import { bindActionCreators } from "redux";
import PickableCardList from "./PickableCardList";
import SelectedCardsTypeList from "./SelectedCardsTypeList";
import SelectedCardsTypeListImage from "./SelectedCardsTypeListImage";
import { Redirect } from "react-router-dom";
import { connectAsPlayer, closeSocket } from "../../api";
import TableData from "./TableData";
import DraftedCardsData from "./DraftedCardsData";

class ParticipantViewTable extends Component {
  constructor(props) {
    super(props);
    this.state = { pickedCardTab: "Info", viewType: "Text" };
  }

  componentDidMount() {
    this.props.clearError();
    if (this.props.tableData && this.props.tableData.draftTableId) {
      this.refreshTableData(this.loadHiddenCardsState);
      connectAsPlayer(this.props.tableData.draftTableId, (message) => {
        //console.log(message)
        if (message != null) {
          if (
            message.type === "AllPlayersRefresh" ||
            (message.playerId != null &&
              message.type === "CheckPacks" &&
              message.playerId === this.props.tableData.draftPlayerId)
          ) {
            this.refreshTableData();
          }
          else if (message.type === "QuickTableDataRefresh")
          {
            this.refreshQuickTableData();
          }
        }
      });
    }
  }

  loadHiddenCardsState = (gameData) => {
    if (gameData != null) {
      this.props.loadHiddenCards(gameData.draftTableId);
    }
  };

  componentWillUnmount() {
    closeSocket();
  }

  refreshTableData(callback = null) {
    this.props.getPlayerTableData(
      Object.assign({}, { playerId: this.props.tableData.draftPlayerId }),
      callback
    );
  }

  refreshQuickTableData(callback = null) {
    this.props.getQuickPlayerTableData(
        Object.assign({}, { playerId: this.props.tableData.draftPlayerId }),
        callback
    );
  }

  changeTypeTab(type) {
    this.setState({ pickedCardTab: type });
  }

  toggleViewType() {
    if (this.state.viewType === "Text") {
      this.setState({ viewType: "Image" });
    } else {
      this.setState({ viewType: "Text" });
    }
  }

  getCardsOfTypeDisplay = (type) => {
    let cards = [];
    if (this.props.tableData && this.props.tableData.draftedCards) {
      if (type === "All") {
        cards = this.props.tableData.draftedCards;
      } else {
        cards = this.props.tableData.draftedCards.filter((card) => {
          return card.type === type;
        });
      }
    }

    return cards.length > 0 ? ` (${cards.length})` : "";
  };

  getCardTypeViewComponent = (type) => {
    if (this.state.pickedCardTab === type) {
      if (this.state.viewType === "Text") {
        return (
          <SelectedCardsTypeList
            cardType={type === "All" ? "All" : type.slice(0, -1)}
          />
        );
      } else {
        return (
          <SelectedCardsTypeListImage
            cardType={type === "All" ? "All" : type.slice(0, -1)}
          />
        );
      }
    } else {
      return null;
    }
  };

  render() {
    let total_drafted_cards = this.getCardsOfTypeDisplay("All").replace(' (', '').replace(')', '')
    if(total_drafted_cards === '')
    {
      total_drafted_cards = '0'
    }

    if (this.props.tableData.draftPlayerId) {
      if (this.props.tableData.draftStatus !== "Finished") {
        return (
          <div className="container-fluid">
            <div className="row ">
              <div className="col-8">
                <div className="shadow p-3 mb-5 bg-white rounded pickable-card-list">
                  <div className="alignleft card-list-item-bold">
                    Current Pack
                  </div>
                  <br />
                  <PickableCardList />
                </div>
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <TableData />
                </div>
              </div>
              <div className="col">
                <div className="shadow p-3 mb-5 bg-white rounded selected-card-group">
                  <div id="textbox">
                    <p className="alignleft card-list-item-bold">
                      Total Drafted Cards ({total_drafted_cards} of 60)
                    </p>
                    <div className="alignright">
                      {this.state.pickedCardTab !== "Info" && <button
                        type="button"
                        className="btn-sm btn-dark"
                        onClick={() => this.toggleViewType()}
                      >
                        Switch View Type
                      </button>}
                    </div>
                  </div>
                  <ul className="nav nav-tabs picked-cards-tabs">
                    <li className="nav-item">
                      <button
                          className={`link-button nav-link tab-text ${
                              this.state.pickedCardTab === "Info"
                                  ? "active remove-outline"
                                  : ""
                          }`}
                          onClick={() => this.changeTypeTab("Info")}
                      >
                        Info
                      </button>
                    </li>
                      <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "All"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("All")}
                      >
                        All{this.getCardsOfTypeDisplay("All")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Forwards"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Forwards")}
                      >
                        Forwards{this.getCardsOfTypeDisplay("Forward")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Summons"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Summons")}
                      >
                        Summons{this.getCardsOfTypeDisplay("Summon")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Monsters"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Monsters")}
                      >
                        Monsters{this.getCardsOfTypeDisplay("Monster")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`link-button nav-link tab-text ${
                          this.state.pickedCardTab === "Backups"
                            ? "active remove-outline"
                            : ""
                        }`}
                        onClick={() => this.changeTypeTab("Backups")}
                      >
                        Backups{this.getCardsOfTypeDisplay("Backup")}
                      </button>
                    </li>
                  </ul>
                  {this.state.pickedCardTab === "Info" && <DraftedCardsData/>}
                  {this.getCardTypeViewComponent("All")}
                  {this.getCardTypeViewComponent("Forwards")}
                  {this.getCardTypeViewComponent("Summons")}
                  {this.getCardTypeViewComponent("Monsters")}
                  {this.getCardTypeViewComponent("Backups")}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <Redirect to="/deck" />;
      }
    } else {
      return <Redirect to="/join_table" />;
    }
  }
}
const mapStateToProps = (state) => ({
  tableData: state.tableData,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantViewTable);
