// api/index.js
import io from "socket.io-client";
const keys = require("../config/keys");
let socket;

function connectAsPlayer(roomId, cb) {
  socket = io(window.location.origin + '/', {
    reconnection: false,
    query: `roomId=${roomId}`,
  });

  socket.emit("subscribe", { roomId: roomId });
  socket.on("check_cards", (message) => {
    cb(message);
  });
}

function connectAsAdmin(roomId, cb) {
  socket = io(window.location.origin + '/', {
    reconnection: false,
    query: `roomId=${roomId}`,
  });

  socket.emit("subscribe", { roomId: roomId });
  socket.on("admin_update", (message) => {
    cb(message);
  });
}

function closeSocket() {
  if (socket != null) {
    socket.close();
  }
}

export { connectAsPlayer, connectAsAdmin, closeSocket };
