import React, { Component } from "react";
import { connect } from "react-redux";
import SelectedCardItem from "./SelectedCardItem";

class SelectedCardsElementGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCardRows = () => {
    let rows = [];
    this.props.selectedCards.forEach((card) =>
      rows.push(
        <SelectedCardItem
          deckEditMode={this.props.deckEditMode}
          key={card.code}
          cardData={card}
          cardNum={card.code}
        />
      )
    );
    return rows;
  };

  render() {
    let rows = this.getCardRows();

    if (rows.length > 0) {
      return (
        <div>
          <div className="element-separator">
            {this.props.elementType.toUpperCase() + ` (${rows.length})`}
          </div>
          <div className="container">{rows}</div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default connect(null, null)(SelectedCardsElementGroup);
